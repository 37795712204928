import React from 'react'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import { Helmet } from 'react-helmet'
import Floater from 'components/floating-grid'
import Layout from 'components/layout'
// import Copy from "components/category/copy";
import Content from 'components/category/content'
import Promos from 'components/promos'
import { graphql } from 'gatsby'

const items = [
  {
    link: '/implements/utility-tractors/',
    imageId: 'utilityTractorImplementsImage',
    name: 'Utility Tractors',
    alt: 'Utility Tractors',
  },
]

const breadcrumbsSchema = JSON.stringify({
  '@context': 'http://schema.org',
  '@type': 'BreadcrumbList',
  'itemListElement': [
    {
      '@type': 'ListItem',
      'position': 1,
      'name': 'Hutson Inc',
      'item': 'https://www.hutsoninc.com/',
    },
    {
      '@type': 'ListItem',
      'position': 2,
      'name': 'implements',
      'item': 'https://www.hutsoninc.com/implements/',
    },
  ],
})

const ImplementsPage = ({ data }) => {
  const { allHutsonPromotion, heroImage } = data
  const promos = []
  if (allHutsonPromotion) {
    allHutsonPromotion.nodes.forEach(node => promos.push(node))
  }
  const gridItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>Implements | Hutson Inc</title>
        <meta
          name='description'
          content='Hutson carries a full-line of John Deere and Frontier implements for utility tractors. Find the right equipment to get the job done.'
        />
        <script type='application/ld+json'>{breadcrumbsSchema}</script>
      </Helmet>

      <HeroGradient image={heroImage} heading='Implements' />

      <Content>
        <Floater items={gridItems} />

        {/* <Copy header='Implements'>
                    <p>
                        ...
                    </p>
                </Copy> */}

        <Promos data={promos} type='Implement' />
      </Content>
    </Layout>
  )
}

export const pageQuery = graphql`
  query implementsCategoryQuery($category: String = "implements") {
    allHutsonPromotion(
      filter: { categoryList: { elemMatch: { category: { eq: $category } } } }
      sort: { fields: [endDate], order: DESC }
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    heroImage: file(relativePath: { eq: "implements/implements-header.jpg" }) {
      ...FullWidthImage
    }
    utilityTractorImplementsImage: file(relativePath: { eq: "implements/utility-tractors.jpg" }) {
      ...FloatingGridImage
    }
  }
`

export default ImplementsPage
